import React from 'react';
import './TopMenu.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { MyIDEXXUserType } from '../../types/brm-types';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import Spinner from '../Spinner/Spinner';
import PracticeSelector from '../PracticeSelector/PracticeSelector';
import { useLocation } from 'react-router-dom';

const TopMenu = observer(() => {
  const { user } = useAuth0<MyIDEXXUserType>();
  const loc = useLocation();
  const { globalStore } = useStore();

  return (
    <div className="nav-items">
      {user && (
        <div className="nav-item">
          <a className="spot-di__header-username" href="#" data-cy="user-name-link">
            <span>{user.nickname}</span>
          </a>
        </div>
      )}
      <PracticeSelector disabled={globalStore.practiceSelectorDisabled || loc.pathname !== '/'} />
      <div className="nav-item spot-crossproduct">
        <a href="#" onClick={e => e.preventDefault()} data-cy="app-menu-btn">
          <img src="/images/spot/icons/app-menu.svg" className="app-menu-icon" />
        </a>
      </div>
      <div className="nav-item">
        {globalStore.loggingOut ? <Spinner size={24} /> : <a href="#" role="logout-link" onClick={() => globalStore.signOut()} data-cy="sign-out-link">Sign out</a>}
      </div>
    </div>
  );
});

export default TopMenu;
