export interface Contact {
  name: string;
  email: string;
  phone: string;
}

export interface Statistics {
  upgradeServers: number;
  upgradeWorkstations: number;
  doNotUpgrade: number;
}

export interface AcceptedBy {
  name: string;
  email: string;
  timestamp: number;
}

export type PracticeUpgradeStatus = '' | 'Scheduled' | 'Cancelled' | 'Cancelled (to reschedule)' | 'Rescheduled';

export type AgentStatus = 'UPGRADE_NOT_SCHEDULED' | 'UPGRADE_PENDING' | 'UPGRADE_COMPLETE' | 'UPGRADE_FAILED' | 'OFFLINE' | 'REFRESH_IN_PROGRESS';

export interface PracticeConfig {
  sapId: string;
  practiceName: string;
  scheduleUpgradeStatus?: PracticeUpgradeStatus;
  scheduleUpgradeTimestamp?: number;
  utcOffset?: number;
  timeZoneName?: string;
  scheduleTimestamp?: number;
  scheduledBy?: string;
  statistics?: Statistics;
  contacts?: Contact[];
  successAgentIds?: string[];
  failedAgentIds?: string[];
  scheduleError?: 'Kaseya' | 'Communication';
  pdfUrl?: string;
  lockedByEmail?: string;
  lockedByName?: string;
  lockEndTimestamp?: number;
  isLocked?: boolean;
  serverAgentId?: string;
  serverStatus?: AgentStatus;
  acknowledgementsLastAccepted?: AcceptedBy;
  acknowledgmentsPdfUrl?: string;
}

export const AuditStatus = {
  QUALIFIED: 'Q',
  REQUIRED: 'R',
  PENDING: 'P',
  UNQUALIFIED: 'U',
  ERROR: 'E',
} as const;

export const UpgradeStatus = {
  UPGRADE: 'U',
  DO_NOT_UPGRADE: 'DNU',
  UPGRADE_MANUALLY_APPROVED: 'UMA',
} as const;

export interface AgentQualifications {
  os?: boolean;
  processor?: boolean;
  memory?: boolean;
  csVersion?: boolean;
  csDrive?: boolean;
  diskSize?: boolean;
  freeSpace?: boolean;
  freeSpaceOnDriveC?: boolean;
  connectivity?: boolean;
  powershell?: boolean;
}

export interface AgentData {
  checked?: boolean;
  sapId: string;
  agentId: string;
  online: number;
  type: string;
  label?: string;
  name: string;
  status: typeof UpgradeStatus[keyof typeof UpgradeStatus];
  lastAuditTimestamp: number;
  auditStatus: typeof AuditStatus[keyof typeof AuditStatus];
  os: string;
  processor: string;
  csVersion: string;
  csDrive?: string;
  lastCheckInTime: string;
  cachedTimestamp: number;
  utcOffset?: number;
  timeZoneName?: string;
  isCloud?: boolean;
  memoryMb?: number;
  diskSizeMb?: number;
  freeSpaceMb?: number;
  diskCRequiredFreeSpace?: number;
  qualifications?: AgentQualifications;
  auditLoading?: boolean;
  rowLoading?: boolean;
}

export interface RoleDefinition {
  roleId: string;
  roleLevel: number;
  resourcePermissions: ResourcePermission[];
}

export interface ResourcePermission {
  resourceId: string;
  permission: string;
  type?: string;
  active?: boolean;
}

export interface Role {
  name: string;
  description?: string;
  level: number;
  global: boolean;
  hidden: boolean;
}

export interface User {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  createdAt?: string;
  lastAccessAt?: string;
  roles: Role[];
}

export interface AuditEvent {
  id: string;
  date: Date;
  event: string;
  type: string;
  sapId: string;
  userEmail: string;
  userRole: string;
  agentId: string;
  agentName: string;
  agentStatus: string;
  agentAuditStatus?: string;
  agentLabel?: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  requestUrl: string;
  request: string;
  response: string;
  success: boolean;
  managementUserEmail: string;
  managementUserFirstName: string;
  managementUserLastName: string;
  managementUserPhone: string;
  managementUserRoles: string;
}

export const isAgentUpgradeQualified = (a: AgentData): boolean =>
  (a.auditStatus === AuditStatus.QUALIFIED && (a.status === UpgradeStatus.UPGRADE || a.status === UpgradeStatus.UPGRADE_MANUALLY_APPROVED))
  || (a.auditStatus === AuditStatus.UNQUALIFIED && a.status === UpgradeStatus.UPGRADE_MANUALLY_APPROVED);

export const isUpgradeScheduled = (practice?: PracticeConfig): boolean =>
  practice?.scheduleUpgradeStatus === 'Scheduled' || practice?.scheduleUpgradeStatus === 'Rescheduled';

