import { action, computed, observable, runInAction } from 'mobx';
import AbstractStore from './AbstractStore';
import { AgentData, AuditStatus, isUpgradeScheduled, PracticeConfig, UpgradeStatus } from '../types/practice-types';
import { isDefined, sortByField } from '../utils/object-utils';
import type { ModalProps } from '../components/Modal/Modal';
import { messageExitUpgrade, messageSessionEnding } from '../layouts/BaseLayout/renderers';
import type { NavigateFunction } from 'react-router';
import dayjs from 'dayjs';
import { PRACTICE_PATH } from './constants';
import type { Sap } from '../types/users-types';
import { allowedUpgradeEventInterval } from '../utils/date-utils';
import EventIntervalsHandler from '../utils/event-intervals-handler';
import { storageService } from '../services/storage.service';
import RootStore from './RootStore';

const MODAL_ID = 'global-modal';

const LOGGING_OUT = 'LOGGING_OUT';

export default class GlobalStore extends AbstractStore {
  @observable _sapIdChanged = true;

  @observable selectedSap: Sap = { id: '', name: '' };

  @observable practiceSelectorDisabled = false;

  @observable isLoggedIn = false;

  @observable isLoading = false;

  @observable private _loggingOut = false;

  @observable practice: PracticeConfig | undefined;

  @observable agentsData: AgentData[] = [];

  @observable eventIntervalsHandler: EventIntervalsHandler = new EventIntervalsHandler([], 30);

  @observable contactsChanged = false;

  @observable globalModalProps: ModalProps = {
    show: false,
    onOk: () => {},
    onExit: () => {},
    id: MODAL_ID,
    okCaption: '',
  };

  private _navigate: NavigateFunction = () => {};

  private upgradeTaskPopupTimeoutHandle: ReturnType<typeof setTimeout> | undefined = undefined;

  private upgradeTaskTimeoutHandle: ReturnType<typeof setTimeout> | undefined = undefined;

  public constructor(rootStore: RootStore) {
    super(rootStore);
    this._loggingOut = storageService.getItem(LOGGING_OUT, true) || false;
  }

  public init(navigate: NavigateFunction): void {
    this._navigate = navigate;
  }

  public get navigate(): NavigateFunction {
    return this._navigate;
  }

  @computed
  public get loggingOut(): boolean {
    return this._loggingOut;
  }

  public set loggingOut(value: boolean) {
    this._loggingOut = value;
    storageService.replace(LOGGING_OUT, value, true);
  }

  @computed
  public get userSaps(): Sap[] {
    return sortByField(this.root.apiStore.user?.saps || [], 'id');
  }

  @action
  public changeSelectedSap(sapId: string, clear = true): void {
    if (this.selectedSap.id !== sapId) {
      this.sapIdChanged = true;
      const sap = this.userSaps.find(us => us.id === sapId);
      this.selectedSap = sap ? sap : { id: '', name: '' };
    } else if (clear) {
      this.selectedSap = { id: '', name: '' };
    }
  }

  @action
  public setLoading(value: boolean): void {
    this.isLoading = value;
  }

  @action
  async fetchEventIntervals(): Promise<EventIntervalsHandler | undefined> {
    try {
      const allowedInterval = allowedUpgradeEventInterval();
      const events = await this.root.apiStore.findScheduledMaintenancesBetweenDates(allowedInterval.start, allowedInterval.end);
      runInAction(() => this.eventIntervalsHandler = new EventIntervalsHandler(events.map(e => ({ start: e.startDateTime, end: e.endDateTime })), 30));
      this.eventIntervalsHandler.setAllowedInterval(allowedInterval);
      return this.eventIntervalsHandler;
    } catch (e: any) {
      alert(e.toString());
    }
  }

  @action
  async fetchPracticeData(withAgents = true, initialCheck = false): Promise<void> {
    const practice = await this.root.apiStore.getPractice(this.sapId, initialCheck);
    const agentsData = withAgents ? await this.root.apiStore.getCachedAgents(this.sapId) : this.agentsData;
      runInAction(() => {
      this.practice = practice;
      this.agentsData = agentsData;
      this.contactsChanged = false;
    });
  }

  @computed
  public get scheduleUpgradeTimeSet(): boolean {
    return isDefined(this.practice?.scheduleUpgradeTimestamp) && isDefined(this.practice?.utcOffset);
  }

  @computed
  public get upgradeScheduled(): boolean {
    return isUpgradeScheduled(this.practice);
  }

  @computed
  public get agentsReady(): boolean {
    if (this.agentsData.length === 0) return false;
    if (this.agentsData.filter(d => d.type === 'Server' && d.status === UpgradeStatus.UPGRADE).length !== 1) {
      return false;
    }
    return this.agentsData.every(d => d.status !== UpgradeStatus.UPGRADE || d.auditStatus === AuditStatus.QUALIFIED);
  }

  @action
  public showOkCancelPopup(header: any, children: any, okCaption: string, cancelCaption: string, onOk: () => void | Promise<void>, onCancel?: () => void | Promise<void>,
      closeOnClickOutside = false, okAutoHide = true, exitOkAction = false): void {
    const action = async (ok: boolean): Promise<void> => {
      runInAction(() => this.globalModalProps.disabled = true);
      if (ok) {
        await onOk();
      } else if (onCancel) {
        await onCancel();
      }
      runInAction(() => {
        this.globalModalProps.disabled = false;
        if (!ok || okAutoHide) this.globalModalProps.show = false;
      });
    };

    this.globalModalProps = {
      id: MODAL_ID,
      cypressData: MODAL_ID,
      show: true,
      okCaption,
      cancelCaption,
      header,
      children,
      onOk: () => action(true),
      onCancel: () => action(false),
      onExit: () => action(exitOkAction),
      onClickOutside: closeOnClickOutside ? () => action(false) : undefined,
    };
  }

  public showOkCancelPopupAsync(header: any, children: any, okCaption: string, cancelCaption: string, onOk: () => void | Promise<void>, onCancel?: () => void | Promise<void>,
                                closeOnClickOutside = false, okAutoHide = true, exitOkAction = false): Promise<void> {
    return new Promise(resolve => this.showOkCancelPopup(
      header,
      children,
      okCaption,
      cancelCaption,
      async () => { onOk && await onOk(); resolve(); },
      async () => { onCancel && await onCancel(); resolve(); },
      closeOnClickOutside,
      okAutoHide,
      exitOkAction
    ));
  }

  @action
  public showOkPopup(header: any, children: any, onOk?: () => void, okCaption?: string): void {
    const action = (): void => {
      runInAction(() => this.globalModalProps.show = false);
      onOk && onOk();
    };
    this.globalModalProps = {
      id: MODAL_ID,
      cypressData: MODAL_ID,
      show: true,
      okCaption: okCaption || 'OK',
      header,
      children,
      onClickOutside: onOk ? () => action() : undefined,
      onOk: action,
      onExit: action,
    };
  }

  public showOkPopupAsync(header: any, children: any, onOk?: () => void, okCaption?: string): Promise<void> {
    return new Promise(resolve => this.showOkPopup(header, children, () => { onOk && onOk(); resolve(); }, okCaption));
  }

  public showExitUpgradePopup(onOk: () => void): void {
    this.showOkCancelPopup(
      'Exit Upgrade?',
      messageExitUpgrade(),
      'Save and Exit',
      'Return to Upgrade',
      onOk,
    );
  }

  public showSessionEndingPopup(): void {
    this.showOkCancelPopup(
      'Session Ending',
      messageSessionEnding(),
      'Extend Session',
      'End Session',
      () => this.extendUpgradeTaskSessionLock(),
      () => this.navigate('/'),
    );
  }

  public setUpgradeTaskSessionEndTimeouts(): void {
    this.clearUpgradeTaskSessionEndTimeouts();
    if (this.practice?.isLocked && this.practice.lockedByEmail === this.root.apiStore.userEmail) {
      const endTime = dayjs(this.practice.lockEndTimestamp);
      let timeoutMs = endTime.add(-5, 'minutes').diff(dayjs(), 'ms');
      if (timeoutMs < 1000) timeoutMs = 1000;
      this.upgradeTaskPopupTimeoutHandle = setTimeout(() => this.showSessionEndingPopup(), timeoutMs);
      this.upgradeTaskTimeoutHandle = setTimeout(() => this.navigate('/'), endTime.diff(dayjs(), 'ms'));
      console.log(`Upgrade task session timeout is set to ${timeoutMs / 1000}s`);
    }
  }

  public clearUpgradeTaskSessionEndTimeouts(): void {
    if (this.upgradeTaskPopupTimeoutHandle) {
      clearTimeout(this.upgradeTaskPopupTimeoutHandle);
      this.upgradeTaskPopupTimeoutHandle = undefined;
      clearTimeout(this.upgradeTaskTimeoutHandle);
      this.upgradeTaskTimeoutHandle = undefined;
      console.log('Upgrade task session timeout is cleared');
    }
  }

  public async extendUpgradeTaskSessionLock(): Promise<void> {
    const practice = await this.root.apiStore.backendPost(PRACTICE_PATH, {
      action: 'EXTEND_SCHEDULE_UPGRADE_PROCESS',
      sapId: this.sapId,
    });
    runInAction(() => this.practice = practice);
    this.setUpgradeTaskSessionEndTimeouts();
  }

  public async endUpgradeTaskSessionIfStarted(): Promise<void> {
    this.clearUpgradeTaskSessionEndTimeouts();
    if (this.selectedSap.id !== '' && this.practice?.isLocked) {
      console.log(`Unlocking upgrade task session for sapId=${this.selectedSap.id}`);
      await this.root.apiStore.backendPost(PRACTICE_PATH, {
        action: 'EXIT_SCHEDULE_UPGRADE_PROCESS',
        sapId: this.selectedSap.id,
      });
      runInAction(() => {
        this.isLoggedIn = false;
        this.globalModalProps.show = false;
      });
    }
  }

  public async signOut(): Promise<void> {
    this.loggingOut = true;
    await this.endUpgradeTaskSessionIfStarted();

    for (const storeName of Object.keys(this.root)) {
      const store = this.root[storeName] as AbstractStore;
      if (!(store instanceof GlobalStore)) await store.signOut();
    }
  }
  public onChangeSapId(): void {
    for (const storeName of Object.keys(this.root)) {
      const store = this.root[storeName] as AbstractStore;
      if (!(store instanceof GlobalStore)) store.onChangeSapId();
    }
  }
}
