import { action, computed, observable, runInAction } from 'mobx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { allowedUpgradeEventInterval, roundTimestamp, timeZoneNameFromOffset } from '../../utils/date-utils';
import { PracticeConfig } from '../../types/practice-types';
import { PRACTICE_PATH } from '../../store/constants';
import AbstractUpgradeTaskStore from '../../store/AbstractUpgradeTaskStore';
import { MessageNoTimeAvailable } from './renderers';
import React from 'react';
import EventIntervalsHandler from '../../utils/event-intervals-handler';

dayjs.extend(utc);
dayjs.extend(timezone);

export default class ScheduleUpgradeStore extends AbstractUpgradeTaskStore {
  @observable scheduleUpgradeTimestamp = 0;

  @observable utcOffset = 0;

  @observable timeZoneName = '';

  @observable saveLoading = false;

  @observable continueDisabled = false;

  @observable showInitWarnings = true;

  @computed
  public get practice(): PracticeConfig | undefined {
    return this.root.globalStore.practice;
  }

  @computed
  public get dateStr(): string {
    return dayjs(this.scheduleUpgradeTimestamp).utcOffset(this.utcOffset).format('D-MMM YYYY[, at ]h:mm a');
  }

  @computed
  public get eventIntervalsHandler(): EventIntervalsHandler {
    return this.root.globalStore.eventIntervalsHandler;
  }

  @action
  public init(): void {
    super.init();
    this.continueDisabled = false;
    const leastAvailableTimestamp = this.eventIntervalsHandler.nextAvailable(0);
    if (leastAvailableTimestamp === false) {
      this.continueDisabled = true;
      this.scheduleUpgradeTimestamp = roundTimestamp(allowedUpgradeEventInterval().start, 30, true);
      if (this.showInitWarnings) {
        this.root.globalStore.showOkPopup(
          'No Upgrade Times Available',
          React.createElement(MessageNoTimeAvailable),
        );
      }
    } else if (this.sapIdChanged) {
      const practice = this.practice;
      this.utcOffset = practice?.utcOffset || 0;
      this.timeZoneName = this.practice?.timeZoneName || timeZoneNameFromOffset(this.utcOffset);
      if (!practice?.scheduleUpgradeTimestamp) {
        this.scheduleUpgradeTimestamp = leastAvailableTimestamp;
      } else {
        this.scheduleUpgradeTimestamp = this.eventIntervalsHandler.nextAvailable(practice.scheduleUpgradeTimestamp)
          || this.eventIntervalsHandler.prevAvailable(practice.scheduleUpgradeTimestamp)
          || leastAvailableTimestamp;
      }
      this.sapIdChanged = false;
    }
  }

  @action
  public async confirmSchedule(): Promise<void> {
    runInAction(() => this.saveLoading = true);
    try {
      const practice = await this.root.apiStore.backendPost(PRACTICE_PATH, {
        action: 'UPDATE_SCHEDULE_UPGRADE_TIME',
        sapId: this.sapId,
        scheduleUpgradeTimestamp: this.scheduleUpgradeTimestamp,
        utcOffset: this.utcOffset,
      });
      void this.audit('UPGRADE_TASK', 'SCHEDULE_PAGE_CONTINUE');
      runInAction(() => this.root.globalStore.practice = practice);
      this.navigate('/confirmation');
    } catch (err: any) {
      this.root.globalStore.showOkPopup(
        'Cornerstone Upgrade',
        err.toString(),
        () => this.navigate('/'),
      );
    }
    runInAction(() => this.saveLoading = false);
  }

  @action
  public async exitUpgrade(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'SCHEDULE_PAGE_EXIT_UPGRADE');
    this.navigate('/');
  }

  @action
  public close(): void {
    super.close();
    this.showInitWarnings = true;
  }
}
