import { action, computed, observable, runInAction } from 'mobx';
import type { SortingObservable } from '../../components/Grid/Grid';
import { GridColDef } from '../../components/Grid/Grid';
import AbstractStore from '../../store/AbstractStore';
import type { Role, User } from '../../types/practice-types';
import { isIdexxEmailAddress, sortByField } from '../../utils/object-utils';
import { toUtcFormattedDate } from '../../utils/date-utils';
import { RoleApi, UserApi } from '../../types/users-types';
import { actionsRenderer, rowCellRenderer } from './renderers';
import { USERS_PATH } from '../../store/constants';
import { regexToSearchWithWildcards } from '../../utils/search-utils';

const emptyUser = (): User => ({ firstName: '', lastName: '', email: '', phone: '', roles: [] });
const isEmptyUser = (user: User): boolean => Object.keys(user).filter(key => key !== 'roles').every(key => !user[key]);
const equalUser = (user1: User, user2: User): boolean => Object.keys(user1).filter(key => key !== 'roles').every(key => user1[key] === user2[key]);

interface Loaders {
  page?: boolean;
  userAction?: boolean;
}

export default class UsersManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable users: User[] = [];

  @observable roles: Role[] = [];

  @observable error: string | null = null;

  @observable currentUser: User = emptyUser();

  @observable currentUserError: User = emptyUser();

  @observable currentUserIndex: number | undefined = undefined;

  @observable addMode = false;

  @observable currentUserRoleIndices: number[] = [];

  @observable currentUserRolesChanged = false;

  @observable hideGuests = true;

  @observable searchText = '';

  @observable sorting: SortingObservable<User> = {};

  @computed
  public get filteredUsers(): User[] {
    let filtered = (this.users || []).filter(u => !isEmptyUser(u));
    if (filtered.length > 0) {
      if (this.hideGuests) {
        filtered = filtered.filter(u => {
            return isEmptyUser(u) || u.roles.length > 0;
        });
      }
      if (this.searchText !== '') {
        const regex = regexToSearchWithWildcards(this.searchText);
        filtered = filtered.filter(u => isEmptyUser(u) || regex.test(u.email) || regex.test(u.firstName || '') || regex.test(u.lastName || ''));
      }
      if (this.sorting.field) {
        filtered = sortByField(filtered, this.sorting.field);
        if (this.sorting.order === 'DESC') {
          filtered = filtered.reverse();
        }
      } else {
        filtered = this.applyDefaultSorting(filtered);
      }
    }
    if (this.addMode) filtered.push(emptyUser());
    return filtered;
  }

  @computed
  public get colDefs(): GridColDef[] {
    return [
      { headerName: 'First Name', field: 'firstName', renderer: rowCellRenderer('firstName'), sortable: true },
      { headerName: 'Last Name', field: 'lastName', renderer: rowCellRenderer('lastName'), sortable: true },
      { headerName: 'Email', field: 'email', renderer: rowCellRenderer('email') },
      { headerName: 'Phone (mobile)', field: 'phone', renderer: rowCellRenderer('phone') },
      { headerName: 'Roles', field: 'roles', renderer: rowCellRenderer('roles') },
      { headerName: 'Last Access (UTC)', field: 'lastAccessAt' },
      { headerName: 'Created (UTC)', field: 'createdAt' },
      { headerName: 'Actions', field: 'actions', renderer: actionsRenderer },
    ];
  }

  public async init(): Promise<void> {
    runInAction(() => this.loaders.page = true);
    const currentUserRoleLowestLevel = this.root.apiStore.userGlobalRoleLowestLevel;
    if (currentUserRoleLowestLevel === Infinity) {
      runInAction(() => this.users = []);
    } else {
      const users = await this.findUsersAllowedToBeManagedByCurrentUser(currentUserRoleLowestLevel);
      let roles: RoleApi[] = [];
      if (this.roles.length === 0) {
        roles = await this.findGlobalRolesAllowedToBeAssignedByCurrentUser(currentUserRoleLowestLevel);
      }
      runInAction(() => {
        this.users = users.map(u => {
            const {firstName, lastName, email, phone} = u;
            return {
              firstName,
              lastName,
              email,
              phone,
              roles: u.globalRoles?.map(gr => gr).sort(gr => gr.level) || [],
              lastAccessAt: toUtcFormattedDate(u.lastAccessAt),
              createdAt: toUtcFormattedDate(u.createdAt)
            };
          }
        );
        if (roles.length > 0) {
          this.roles = roles.sort(r => r.level);
        }
        this.loaders.page = false;
        this.currentUserIndex = undefined;
        this.searchText = '';
        this.hideGuests = true;
        this.sorting = {};
      });
    }
  }

  @action
  private async findGlobalRolesAllowedToBeAssignedByCurrentUser(currentUserRoleLowestLevel: number | undefined): Promise<RoleApi[]> {
    return await this.root.apiStore.findRoleByGlobalAndLowestLevel(true, currentUserRoleLowestLevel);
  }

  @action
  private async findUsersAllowedToBeManagedByCurrentUser(currentUserRoleLowestLevel: number | undefined): Promise<UserApi[]> {
    return await this.root.apiStore.findUsersByLowestRoleLevel(currentUserRoleLowestLevel);
  }

  @action
  public addUser(): void {
    if (this.loaders.userAction) return;
    this.addMode = true;
    this.users.push(emptyUser());
    this.currentUser = emptyUser();
    this.currentUser.email = '@idexx.com';
    this.currentUserError = emptyUser();
    this.currentUserIndex = this.filteredUsers.length - 1;
    this.currentUserRoleIndices = [];
    this.currentUserRolesChanged = false;
    void this.audit('USER_MANAGEMENT', 'USER_MANAGEMENT_PAGE_ADD_USER');
  }

  @action
  public editUser(index: number): void {
    this.addMode = false;
    this.currentUser = { ...this.filteredUsers[index] };
    this.currentUserError = emptyUser();
    this.currentUserIndex = index;
    this.currentUserRoleIndices = this.currentUser.roles?.map(r => this.roles.findIndex(ar => ar.name === r.name)).filter(i => i >= 0) || [];
    this.currentUserRolesChanged = false;
    void this.audit('USER_MANAGEMENT', 'USER_MANAGEMENT_PAGE_EDIT_USER');
  }

  @action
  public deleteUser(email: string): void {
    if (this.loaders.userAction) return;
    this.root.globalStore.showOkCancelPopup(
      'Confirm user deletion',
      'Are you sure you want to delete this user?',
      'OK',
      'Cancel',
      () => this.doDeleteUser(email),
    );
  }

  @action
  public async cancelEditingUser(): Promise<void> {
    if (this.currentUserIndex === undefined) return;
    if (this.addMode) {
      runInAction(() => this.users.pop());
    }
    runInAction(() => {
      this.loaders.userAction = false;
      this.addMode = false;
      this.currentUserIndex = undefined;
    });
  }

  @action
  public async validateAndUpdateUser(): Promise<void> {
    if (this.currentUserIndex === undefined) return;
    // to prevent multiple calls of this method
    const currentIndex = this.currentUserIndex;
    runInAction(() => {
      this.currentUserIndex = undefined;
      const { firstName, lastName, phone, email } = this.currentUser;
      this.currentUserError = emptyUser();
      if (!firstName) this.currentUserError.firstName = 'First name should be set';
      if (!lastName) this.currentUserError.lastName = 'Last name should be set';
      if (email) {
        if (!isIdexxEmailAddress(email)) {
          this.currentUserError.email = 'Invalid email format';
        } else if (this.addMode && this.isEmailAddressAlreadyExists(email)) {
          this.currentUserError.email = 'A user with this email already exists';
        }
      } else {
        this.currentUserError.email = 'Email should be set';
      }
      if (phone && !/^\d{10}$/.test(phone)) this.currentUserError.phone = 'Invalid phone format';
    });
    if (!isEmptyUser(this.currentUserError)) {
      runInAction(() => this.currentUserIndex = currentIndex);
    } else {
      if (!equalUser(this.currentUser, this.filteredUsers[currentIndex]) || this.currentUserRolesChanged) {
        await this.updateUser();
      }
      runInAction(() => this.currentUserIndex = undefined);
    }
  }

  @action
  public changeRoleIndices(currentUserIndex: number, editUserRoleIndices: number[]): void {
    const initialUserRoleIndices: number[] = this.getInitialUserRoleIndices(currentUserIndex);
    const isSameArrays = (initialUserRoleIndices.length == editUserRoleIndices.length) && initialUserRoleIndices.every(item => editUserRoleIndices.includes(item)) && editUserRoleIndices.every(item => initialUserRoleIndices.includes(item));
    this.currentUserRolesChanged = !isSameArrays;
    this.currentUserRoleIndices = editUserRoleIndices;
  }

  public getInitialUserRoleIndices(currentUserIndex: number): number[] {
    const initialUser = this.filteredUsers[currentUserIndex];
    return initialUser.roles.map(ur => this.roles.findIndex(r => r.name === ur.name)).filter(i => i > -1);
  }

  @action
  public back(): void {
    void this.audit('USER_MANAGEMENT', 'USER_MANAGEMENT_PAGE_BACK');
    this.navigate(-1);
  }

  @action
  public done(): void {
    void this.audit('USER_MANAGEMENT', 'USER_MANAGEMENT_PAGE_DONE');
    this.navigate('/');
  }

  @action
  private async updateUser(): Promise<void> {
    runInAction(() => {
      this.loaders.userAction = true;
    });
    const addMode = this.addMode;
    this.addMode = false;
    let user: User = {
      ...this.currentUser,
      email: this.currentUser.email.toLowerCase(),
      roles: this.currentUserRoleIndices.map(i => this.roles[i]),
    };
    runInAction(() => {
      if (addMode) {
        this.users.pop();
      }
    });
    try {
      const userRequest = {
        action: addMode ? 'CREATE' : 'UPDATE',
        ...user,
        roleNames: user.roles?.map(r => r.name),
      };
      void this.audit('USER_MANAGEMENT', `USER_MANAGEMENT_PAGE_${userRequest.action}_USER`);
      const userResponse = await this.root.apiStore.backendPost(USERS_PATH, userRequest);
      const {firstName, lastName, email, phone, createdAt, globalRoles} = userResponse;
      user = {
        firstName,
        lastName,
        email,
        phone,
        roles: globalRoles?.map(gr => gr) || [],
        createdAt: toUtcFormattedDate(createdAt)
      };
      runInAction(() => {
        const userIndex = this.users.findIndex(u => u.email === email);
        addMode ? this.users.push(user) : this.users[userIndex] = user;
      });
    } finally {
      runInAction(() => {
        this.loaders.userAction = false;
      });
    }
  }

  private async doDeleteUser(email: string): Promise<void> {
    if (this.loaders.userAction) return;
    runInAction(() => this.loaders.userAction = true);
    try {
      void this.audit('USER_MANAGEMENT', 'USER_MANAGEMENT_PAGE_DELETE_USER');
      await this.root.apiStore.backendPost(USERS_PATH, {
        action: 'DELETE',
        email: email,
      });
      runInAction(() => this.users = this.users.filter(u => u.email !== email));
    } catch {
      // ignore
    }
    runInAction(() => {
      this.currentUserIndex = undefined;
      this.loaders.userAction = false;
    });
  }

  private applyDefaultSorting(users: User[]): User[] {
    return [...users].sort((u1, u2) => (u1.lastName || '').localeCompare((u2.lastName || ''))
      || (u1.firstName || '').localeCompare((u2.firstName || '')));
  }

  private isEmailAddressAlreadyExists(email: string): boolean {
    return this.users.some(u => u.email.toUpperCase() === email.toUpperCase());
  }
}
